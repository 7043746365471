import React, { useState } from 'react';
import './App.css';
import VotingForm from './components/VotingForm';
import ResultsGraph from './components/ResultsGraph';

function App() {
  const [showVoting, setShowVoting] = useState(true);

  const handleShowResults = () => {
    setShowVoting(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Votación para Cena de Navidad</h1>
        {showVoting ? (
          <VotingForm onShowResults={handleShowResults} />
        ) : (
          <>
            <button onClick={() => setShowVoting(true)} className="option-button">Volver a Votar</button>
            <ResultsGraph />
          </>
        )}
      </header>
    </div>
  );
}

export default App;


/*import React, { useState } from 'react';
import './App.css';
import VotingForm from './components/VotingForm';
import ResultsGraph from './components/ResultsGraph';

function App() {
  const [votes, setVotes] = useState({});
  const [showVoting, setShowVoting] = useState(true);

  const handleVoteSubmit = (vote) => {
    // Actualiza el estado con los nuevos votos y nombres
    const newVotes = { ...votes };
    vote.selectedWeekends.forEach(date => {
      // Asegúrate de que cada fecha sea una clave en el objeto de votos
      if (!newVotes[date]) {
        newVotes[date] = { count: 0, names: [] };
      }
      newVotes[date].count += 1;
      newVotes[date].names.push(vote.name);
    });
    setVotes(newVotes);
    setShowVoting(false); // Ocultar el formulario después de votar
  };

  // Prepara los datos para el gráfico
  const dataForGraph = {
    labels: Object.keys(votes).map(date => `${date} (${votes[date].count})`), // Agrega el recuento de votos al label
    datasets: [{
      label: 'Votos por Fecha',
      data: Object.values(votes).map(vote => vote.count), // Usa el recuento de votos para el dato
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    }],
  };

 

  // Renderiza la lista de votantes
  const renderVotersList = () => (
    Object.entries(votes).map(([date, details]) => (
      <div key={date}>
        <h3>{date}</h3>
        <ul>
          {details.names.map((name, index) => <li key={index}>{name}</li>)}
        </ul>
      </div>
    ))
  );

  //Funcion que muestra los resultados

  const handleShowResults = () => {
      setShowVoting(false);
    };

   
    return (
      <div className="App">
        <header className="App-header">
          <h1>Votación para Cena de Navidad</h1>
          {showVoting ? (
            <VotingForm onSubmitVote={handleVoteSubmit} />
          ) : (
            <>
              <button onClick={() => setShowVoting(true)} className="option-button">Volver a Votar</button>
              <ResultsGraph data={dataForGraph} />
              {renderVotersList()}
            </>
          )}
        </header>
      </div>
    );
  }
export default App;

*/