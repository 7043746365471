import React, { useState } from 'react';
import '../assets/styles/VotingForm.css';
import config from '../config'; // Asegúrate de ajustar la ruta de importación según sea necesario
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const VotingForm = ({ onSubmitVote, onShowResults }) => {
  const [name, setName] = useState('');
  const [selectedWeekends, setSelectedWeekends] = useState([]);

  const weekendDates = [
    "06 de diciembre de 2024 (Viernes)",
    "07 de diciembre de 2024 (Sábado)",
    "13 de diciembre de 2024 (Viernes)",
    "14 de diciembre de 2024 (Sábado)",
    "20 de diciembre de 2024 (Viernes)",
    "21 de diciembre de 2024 (Sábado)",
    "27 de diciembre de 2024 (Viernes)",
    "28 de diciembre de 2024 (Sábado)"
  ];

  const handleWeekendChange = (event) => {
    const value = event.target.value;
    setSelectedWeekends(
      selectedWeekends.includes(value)
        ? selectedWeekends.filter(w => w !== value)
        : [...selectedWeekends, value]
    );
  };



  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validación del nombre
    if (!name.trim()) {
      toast.error('Debes de indicar un nombre para poder votar');
      return;
    }

    // Validación de la selección de días
    if (selectedWeekends.length === 0) {
      toast.error('Debes de marcar al menos un día para poder votar');
      return;
    }
  
    // Función para mapear las fechas al formato "yyyy-MM-DD"
    const formatDate = (dateString) => {
      const months = {
        enero: '01',
        febrero: '02',
        marzo: '03',
        abril: '04',
        mayo: '05',
        junio: '06',
        julio: '07',
        agosto: '08',
        septiembre: '09',
        octubre: '10',
        noviembre: '11',
        diciembre: '12',
      };
      
      const parts = dateString.split(' ');
      const day = parts[0];
      const month = months[parts[2].toLowerCase()];
      const year = parts[4];
      
      // Asegurarse de que el día tenga dos dígitos
      const formattedDay = day.length === 1 ? `0${day}` : day;
  
      // Formatear la fecha
      return `${year}-${month}-${formattedDay}`;
    };
  
    // Mapear las fechas seleccionadas al formato "yyyy-MM-DD"
    const formattedSelectedWeekends = selectedWeekends.map(formatDate);
  
    try {
      console.log('Se va a votar: ' + name + ', con fechas: ' + formattedSelectedWeekends);
      console.log('JSON: ' +  JSON.stringify({ name, selectedWeekends: formattedSelectedWeekends }));
      console.log('entorno: ' + config.apiUrl );
      //const response = await fetch('http://localhost:3001/vote', {
      const response = await fetch(`${config.apiUrl}/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, selectedWeekends: formattedSelectedWeekends }),
      });
  
      if (response.ok) {
        // Manejar la respuesta del servidor
        console.log('Voto enviado con éxito');
        //alert('Voto registrado'); // Mensaje de confirmación
        toast.success('Voto registrado'); // Muestra una notificación de éxito
      } else {
        // Manejar errores del servidor
        console.error('Error al enviar el voto');
        //alert('Error al registrar el voto'); // Mensaje de error
        toast.error('Error al registrar el voto'); // Muestra una notificación de error
      }
    } catch (error) {
      // Manejar errores de conexión
      console.error('Error al conectar con el servidor', error);
      //alert('Error al conectar con el servidor'); // Mensaje de error
      toast.error('Error al conectar con el servidor'); // Muestra una notificación de error
    }
  };
  

  return (
    <form onSubmit={handleSubmit} className="voting-form">
      <label>
        Nombre:
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="input-name"
        />
      </label>
      <fieldset>
        <legend>Elige los fines de semana disponibles:</legend>
        {weekendDates.map((date, index) => (
          <label key={index} className="weekend-option">
            <input
              type="checkbox"
              value={date}
              onChange={handleWeekendChange}
              className="checkbox-weekend"
            />
            {date}
          </label>
        ))}
      </fieldset>
      <button type="submit" className="submit-button">Votar</button>
      <button type="button" onClick={onShowResults} className="results-button">Ver Resultados</button>
      <ToastContainer position="top-center" autoClose={5000} />
    </form>
  );
};

export default VotingForm;
