import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import '../assets/styles/ResultsGraph.css';
import config from '../config'; // Asegúrate de ajustar la ruta de importación según sea necesario


const ResultsGraph = () => {
  const [graphData, setGraphData] = useState({ labels: [], datasets: [] });
  //const [graphData, setGraphData] = useState({});

  useEffect(() => {
    const fetchResults = async () => {
      try {
        console.log('entorno: ' + config.apiUrl );
       const response = await fetch(`${config.apiUrl}/results`);
        if (response.ok) {
          const data = await response.json();
          console.log("Datos recibidos del servidor:", data); // Agregar esta línea
          setGraphData(transformDataForGraph(data));
        } else {
          console.error('Error al obtener los resultados');
        }
      } catch (error) {
        console.error('Error al conectar con el servidor', error);
      }
    };

    fetchResults();
  }, []);

  const transformDataForGraph = (results) => {
    if (!Array.isArray(results)) {
      console.error('Los resultados no son un array:', results);
      return { labels: [], datasets: [] }; // Devolver un objeto vacío si no hay datos
    }
  
    const labels = results.map(result => result.fecha_votada.split('T')[0]); // Asegurarse de que las fechas no tengan tiempo
    const voteCounts = results.map(result => result.nombres ? result.nombres.split(',').length : 0);
    const voteNames = results.map(result => result.nombres ? result.nombres.split(',') : []);

    return {
      labels: labels,
      datasets: [{
        label: 'Número de Votos',
        data: voteCounts,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1
      }],
      voteNames: voteNames // Agregar esto para mantener los nombres de los votantes
    };
  };
  

  return (
    <div className="graph-container">
      <Bar 
        data={graphData} 
        options={{ 
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function(context) {
                  const label = context.chart.data.labels[context.dataIndex];
                  const voteNames = context.chart.data.voteNames[context.dataIndex];
                  return `${label}: ${voteNames.join(', ')}`;
                }
              }
            }
          }
        }} 
      />
    </div>
  );
};
  

export default ResultsGraph;


/*
import React from 'react';
import { Bar } from 'react-chartjs-2';
import '../assets/styles/ResultsGraph.css';

const ResultsGraph = ({ data }) => {
  return (
    <div className="graph-container">
      <Bar data={data} options={{ 
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }} />
    </div>
  );
};

export default ResultsGraph;
*/